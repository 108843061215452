import moment from 'moment';
import AssetsProviderFactory from 'src/assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { ComplianceType, dateFormat, TABLE_ID } from 'src/utils/constants';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { refinedDemoModeDatePolicy } from 'src/api/collection/collectionRefinery';

type ManageMfeProps = {
  serviceID?: string;
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
  isWex?: boolean;
  demoEnabled?: boolean;
  apiPath?: any;
};

const localProps: ManageMfeProps = {
  serviceID: 'serviceId: ws-hp.com/smcloud',
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
  isWex: false,
  demoEnabled: false,
  apiPath: null,
};

export const setProps = () => {
  const { serviceID, authProvider, localization, stack, isWex, demoEnabled, apiPath } =
    useShellRootContext();

  localProps.serviceID = serviceID;
  localProps.authProvider = authProvider;
  localProps.language = localization.language;
  localProps.stack = stack ?? 1;
  localProps.country = localization.country;
  localProps.isWex = isWex;
  localProps.demoEnabled = demoEnabled;
  localProps.apiPath = apiPath ?? null;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-devices.${subkey}`, args);
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getTableId = (path) => {
  const { isWex } = useShellRootContext();
  if (checkIsFromSolutions(path)) {
    return TABLE_ID.SOLUTIONS_TABLE;
  }
  if (checkIsFromApps(path)) {
    return TABLE_ID.APPS_TABLE;
  }
  let tableId = TABLE_ID.DEVICES_TABLE;
  if (isWex) {
    tableId += '-wex';
  }
  return tableId;
};

export const checkIsFromSolutions = (path) => {
  return path == '/solutions/security/devices';
};

export const checkIsFromApps = (path) => {
  return path.startsWith('/solutions/app');
};

export const refined = (data: any) => {
  if (data === undefined || data === null || data === '') {
    if (typeof data === 'object' && data !== null) {
      return {};
    }
    if (Array.isArray(data)) {
      return [];
    }
    return '--';
  } else {
    return data;
  }
};
export const refinedDate = (data: any, format?: string) => {
  moment.locale('en');
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    if (moment(data).isValid()) return moment(data).format(format ?? dateFormat.default);
    else return '--';
  }
};

export const refinedArray = (data: any) => {
  if (data === undefined || data === null || !data) {
    return [];
  }
  return data;
};

export const isMockUpMode = () => {
  return !!(
    (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') &&
    localStorage.getItem('enable-devices-mockup-data')
  );
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};

export const getPermissionSet = (permission: any, permissionSet: any[]) => {
  for (const key in permission) {
    if (permission[key] instanceof Object) {
      getPermissionSet(permission[key], permissionSet);
    } else {
      permissionSet.push(permission[key]);
    }
  }
  return permissionSet;
};

export const jsonParse = (text: string, defResult = []) => {
  return text ? JSON.parse(text) : defResult;
};

export const objHasKeys = (obj, keys) => {
  const next = keys.shift();
  return obj[next] && (!keys.length || objHasKeys(obj[next], keys));
};

export const setTableColumns = (tableId, columnConfig) => {
  const sessionColumn = sessionStorage.getItem(tableId);
  const parsedColumn = JSON.parse(sessionColumn);
  const initColumnOrder = (newOrder) => {
    sessionStorage.setItem(tableId, JSON.stringify(newOrder));
  };

  if (sessionColumn !== null && parsedColumn.length > 0) {
    initColumnOrder(parsedColumn);
  } else {
    initColumnOrder(columnConfig.cfgInitialColumnsList());
  }
};

export const getStatus = (status: {
  acceptingJobs: boolean;
  printerStateSeverity: string;
  printerState: string;
  connectionState: string;
}) => {
  if (!status || status === null || status === undefined) {
    return 'ERROR';
  }
  const acceptingJobs = status?.acceptingJobs;
  const printerStateSeverity = status?.printerStateSeverity;
  const printerState = status?.printerState;

  if (
    status.connectionState == 'offline' ||
    status.printerState == '--' ||
    status.printerStateSeverity == '--'
  ) {
    return 'UNKNOWN';
  } else if (
    (acceptingJobs === false && printerState !== 'PROCESSING') ||
    printerStateSeverity === 'ERROR'
  ) {
    return 'ERROR';
  } else if (printerStateSeverity === 'WARNING') {
    return 'WARNING';
  } else {
    return 'READY';
  }
};

export const convertToSnakeCase = (input: string): string => {
  return input.toLowerCase().replace(/\s+/g, '_');
};

export const getDateFromTableOrgData = (items, deviceId, colId, demoEnabled) => {
  const item = items.find((item) => {
    if (item.deviceId == deviceId) {
      return true;
    }
  });
  switch (colId) {
    case 'addedDate':
      return item.timestamp.addedDate;
    case 'statusUpdated':
      return item.status.lastUpdatedAt;
    case 'lastSyncTime':
      return item.status.connectionStateLastUpdatedAt;
    case 'policyDateRun':
      return demoEnabled
        ? item.complianceList?.lastRunAt
        : refinedDemoModeDatePolicy(
            item.complianceList?.lastRunAt,
            item.lastUpdatedAt,
            dateFormat.policy_date_run,
          );
  }
};

export const getComplianceScore = (items, deviceId) => {
  const item = items.find((item) => {
    if (item.deviceId == deviceId) {
      return true;
    }
  });
  switch (item.complianceList?.status) {
    case ComplianceType.NonCompliant:
      return 3;
    case ComplianceType.Compliant:
      return 2;
    default:
      // ComplianceType.Unknown
      return 1;
  }
};

export const getAssessmentScore = (items, deviceId) => {
  const item = items.find((item) => {
    if (item.deviceId == deviceId) {
      return true;
    }
  });

  switch (item.solutionMetadata?.[0]?.result) {
    case 'highRisk':
      return 5;
    case 'mediumRisk':
      return 4;
    case 'notAssessed':
    case 'unknown':
      return 3;
    case 'lowRisk':
      return 2;
    case 'passed':
      return 1;
    default:
      return 0;
  }
};

export const getStatusMessage = (status: string) => {
  switch (status) {
    case 'ERROR':
      return t('table.unableToPrint');
    case 'WARNING':
      return t('table.needsAttention');
    case 'READY':
      return t('table.readyToPrint');
    case 'UNKNOWN':
      return t('table.unknown');
  }
};
