import { refined, refinedArray, refinedDate } from 'src/utils/commonMethods';

const refinedCompliances = (data) => {
  return {
    size: data.size,
    limit: data.limit,
    offset: data.offset,
    items: data.items?.map((item) => ({
      deviceId: refined(item.deviceId),
      status: refined(item.status),
      lastRunAt: item.policies.length > 0 ? refinedDate(item.lastRunAt) : '--',
      policies: refinedArray(item.policies),
    })) ?? {
      deviceId: '--',
      status: '--',
      lastRunAt: '--',
      policies: [],
    },
  };
};

export { refinedCompliances };
