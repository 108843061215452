import React from 'react';
import { Modal } from '@veneer/core';

import { SubMfeDirectCloudOnboarding } from 'src/components/molecule';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const DirectCloudOnboardingModal = (props) => {
  const { jshellProps } = useShellRootContext();
  const onCancel = () => {
    props.setShowDCOModal(false);
  };
  const DCOModal = () => {
    return (
      <SubMfeDirectCloudOnboarding
        {...jshellProps}
        {...{ onCancel: onCancel, showModal: props.showDCOModal }}
      />
    );
  };
  return props.showDCOModal ? (
    <Modal
      show={props.showDCOModal}
      align="start"
      closeButton
      maxWidth={768}
      id="modal-id"
      onClose={() => props.setShowDCOModal(false)}
    >
      <DCOModal />
    </Modal>
  ) : (
    <></>
  );
};

export default DirectCloudOnboardingModal;
