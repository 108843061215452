import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import tokens from '@veneer/tokens';
import { t } from 'src/utils/commonMethods';
import { Button, IconCheckboxSelectAll, IconSlashCircle } from '@veneer/core';
import { useStoreState } from 'src/store/useStoreState';
import { useDispatch } from 'react-redux';
import { storeSelectedItems, storeTableData } from 'src/store/devices/action';

type TTableLeftSelectedItemProps = {
  selectedItems: any;
  handleFooterCancel: () => void;
  entireDataCache: any[];
};

const TableLeftSelectedItem = (props: TTableLeftSelectedItemProps) => {
  const { devicesState } = useStoreState();
  const selectedDataLength = props.selectedItems.length;
  const [selectedAllItems, setSelectedAllItems] = useState(false);
  const { tableData, selectedItems } = devicesState;
  const dispatch = useDispatch();

  const handleSelectedAllItems = (isSelectAll) => {
    if (isSelectAll) {
      //select all
      const newTableData = tableData.map((item) => {
        return { ...item, rowConfig: { selected: true } };
      });
      dispatch(storeTableData(newTableData));
      dispatch(storeSelectedItems(props.entireDataCache));
    } else {
      //deselect all
      const newTableData = tableData.map((item) => {
        return { ...item, rowConfig: { selected: false } };
      });
      dispatch(storeTableData(newTableData));
      dispatch(storeSelectedItems([]));
    }
  };

  useEffect(() => {
    if (selectedItems.length == props.entireDataCache.length) {
      setSelectedAllItems(true);
    } else {
      setSelectedAllItems(false);
    }
  }, [selectedItems]);

  return (
    <div>
      <CancelButton
        id="contextual-footer-cancel-button"
        appearance="secondary"
        onClick={props.handleFooterCancel}
        data-testid="contextual-footer-cancel-button"
      >
        {t('table.cancel')}
      </CancelButton>

      <ItemsSelectedBox>
        <label data-testid="contextual-footer-selected-label">
          {selectedDataLength}{' '}
          {selectedDataLength > 1 ? t('table.items_selected') : t('table.item_selected')}
        </label>
      </ItemsSelectedBox>

      <SelectedAllItemsButton
        id="contextual-footer-select-all-button"
        appearance="ghost"
        onClick={() => {
          handleSelectedAllItems(!selectedAllItems);
        }}
        data-testid="contextual-footer-select-all-items-button"
        leadingIcon={!selectedAllItems ? <IconCheckboxSelectAll /> : <IconSlashCircle />}
      >
        {!selectedAllItems
          ? t('table.select_items', {
              totalNumberOfItems: '' + props.entireDataCache.length,
            })
          : t('table.deselect_all')}
      </SelectedAllItemsButton>
    </div>
  );
};

const CancelButton = styled(Button)`
  margin-right: 16px !important;
`;

const ItemsSelectedBox = styled.div`
  display: inline-flex;
  flex: 1 1 100%;
  order: 1;

  font-family: var(--fontRamilyRegular);
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  vertical-align: middle;
  margin-right: 16px !important;
`;

const SelectedAllItemsButton = styled(Button)``;

export default TableLeftSelectedItem;
