import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { refinedCompliances } from './fleetMgtRefinery';
import { isMockUpMode, getMockUpData, getProps } from 'src/utils/commonMethods';
import axios from 'axios';

/**
 * Policy Compliance API
 */
export const fleetMgtAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.fleetMgt);
  const { demoEnabled, apiPath } = getProps();

  const getCompliance = async (collectionId, offset, limit) => {
    let error, response: any;

    if (demoEnabled) {
      const url = apiPath('demo', 'generic', 'printMfeCache', 'deviceCompliances');
      await axios.get(url).then((res) => {
        response = refinedCompliances(res.data);
      });
      return { response, error };
    }

    if (isMockUpMode()) {
      await getMockUpData('fleetMgt/get-compliance').then((data) => {
        response = refinedCompliances(data);
      });
      return { response, error };
    }

    await client
      .getAllDevices(offset, limit)
      .then((res) => {
        response = refinedCompliances(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getCompliance,
  };
};
