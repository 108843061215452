import { t } from 'src/utils/commonMethods';

export const getGroups = async (
  getCollectionsReturn: (offset, limit, name?) => Promise<{ response: any; error: any }>,
  setGroups: (data) => void,
  setErrorForGroups?: (data) => void,
  setTotalCountForGroups?: (data) => void,
) => {
  let totalGroups = [];
  const limit = -1;
  const { response, error } = await getCollectionsReturn(0, limit, undefined);
  if (error != undefined) {
    if (setErrorForGroups != undefined) {
      setErrorForGroups(error);
    }
    return;
  }
  totalGroups = [...response.contents];
  if (response.totalSize > response.contents.length) {
    for (let offset = limit - 1; offset <= response.totalSize; offset = offset + limit) {
      const { response: additionalResponse, error: additionalError } = await getCollectionsReturn(
        0,
        limit,
        undefined,
      );
      if (additionalError == true) {
        if (setErrorForGroups != undefined) {
          setErrorForGroups(additionalError);
        }
        return;
      }
      totalGroups = [...totalGroups, ...additionalResponse.contents];
    }
  }
  const allDevicesGroupUuid = setGroups(totalGroups);
  if (setTotalCountForGroups != undefined) setTotalCountForGroups(totalGroups.length);

  return allDevicesGroupUuid;
};

export const getLokalisedGroupLabel = (name) => {
  if (name == 'All') {
    return t('group.group_all');
  }
  if (name == 'Ungrouped') {
    return t('group.group_ungrouped');
  }
  return name;
};

export const getTreeViewParentNodes = (
  groupData,
  allDevicesGroupUuid,
  ungroupedGroupUuid,
  disableLabel = false,
) => {
  const groupTree = [
    {
      id: (disableLabel ? 'disable_edit-group-label__' : '') + allDevicesGroupUuid,
      label: getLokalisedGroupLabel('All'),
      totalChildren: 0,
      nodes: [],
    },
  ];

  // NOTE: Current spec is to only have 'All' group as a sole parent node.
  groupData.forEach((group) => {
    if (group.id == allDevicesGroupUuid) {
      groupTree[0].totalChildren = group.devices;
      return;
    }
    if (group.id == ungroupedGroupUuid) {
      groupTree[0].nodes.unshift({
        id: (disableLabel ? 'disable_edit-group-label__' : '') + group.id,
        label: getLokalisedGroupLabel(group.name),
        totalChildren: group.devices,
      });
      return;
    }
    groupTree[0].nodes.push({
      id: group.id,
      label: getLokalisedGroupLabel(group.name),
      totalChildren: group.devices,
    });
  });

  return groupTree;
};

type ApiType = 'collection' | 'fleetproxy' | 'deviceCache' | 'compliance';

export const fetchAllWithLoop = async (getAPI, setData, setError, groupId, apiType?: ApiType) => {
  const start = Date.now();
  const limit = apiType == 'deviceCache' ? 200 : 500;
  let offset = 0;
  const totalList = [];

  const filterItemFormat = (res) => {
    if (apiType == 'collection') {
      return res?.contents ?? [];
    } else if (apiType == 'fleetproxy') {
      return res?.content ?? [];
    }
    return res;
  };

  try {
    let hasMore = false;
    do {
      const { response, error } = await getAPI(groupId, offset++, limit);
      const items = filterItemFormat(response);

      if (error) {
        throw new Error(error);
      }
      if (items && items.length > 0) {
        totalList.push(...items);
        hasMore = items.length === limit;
      } else {
        hasMore = false;
      }
    } while (hasMore);
  } catch (error) {
    setError(true);
  }
  const millis = Date.now() - start;

  console.log(`milli seconds elapsed = ${Math.floor(millis)}`);

  setData(totalList);
  return totalList;
};

export const fetchAllAsynchronously = async (
  getAPI,
  setData,
  setError,
  groupId?,
  groupItemsCount?,
  apiType?: ApiType,
  intervalMilliSeconds?: number,
) => {
  const start = Date.now();

  const limit = apiType == 'deviceCache' ? 200 : 500;
  const totalList = [];

  const filterItemFormat = (res) => {
    if (apiType == 'collection') {
      return res?.contents ?? [];
    } else if (apiType == 'fleetproxy') {
      return res?.content ?? [];
    }
    if (apiType == 'compliance') {
      return res?.items ?? [];
    }
    return res;
  };

  try {
    // Initial call to get the first page
    const { response, error } = await getAPI(groupId, 0, limit);
    if (error) {
      throw new Error(error);
    }
    const initialItems = filterItemFormat(response);
    totalList.push(...initialItems);

    // Check if more pages need to be fetched (collection API: groupItemsCount, fleet-proxy API: size)
    const totalItemsCount = groupItemsCount ?? response.size;

    if (totalItemsCount > limit) {
      const pages = Math.ceil(totalItemsCount / limit);
      const promises = [];
      let delay = 0;

      for (let page = 1; page < pages; page++) {
        delay += intervalMilliSeconds;
        promises.push(
          new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
            getAPI(groupId, page, limit),
          ),
        );
      }

      try {
        await Promise.all(promises)
          .then((results) => {
            for (const result of results) {
              const items = filterItemFormat(result.response);
              totalList.push(...items);
            }
          })
          .catch((error) => console.error(error));
      } catch (error) {
        setError(true);
      }
    }
  } catch (error) {
    setError(true);
  }
  const millis = Date.now() - start;

  console.log(`milli seconds elapsed = ${Math.floor(millis)}`);
  setData(totalList);
  return totalList;
};
