import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { useResponsive } from 'src/hooks/useResponsive';
import { GroupErrorMsg } from 'src/components/atom';
import { InfiniteScrollTree } from 'src/components/molecule';
import { MainGroupButton } from 'src/components/organism';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { getTreeViewParentNodes } from 'src/utils/groupMethods';
import { Button, IconChevronRight, SideBar, TreeView, ThemeProvider } from '@veneer/core';
import {
  storeMainGroupSelectedId,
  storeMainGroupSelectedName,
} from 'src/store/devicesGroup/action';
import {
  storeCurrentPage,
  storeSelectedItems,
  storeCfgApiRequestOptions,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';
import { useRefreshHook } from 'src/hooks/useRefreshHook';

type TMainGroupProps = {
  mainGroupData: any[];
  error: boolean;
  isFetchingForTable: boolean;
  cfgApiRequestOptions: any;
  setShowEditGroupModal: (boolean) => void;
  setGroupScrollBarWidth: (number) => void;
};

const MainGroup = (props: TMainGroupProps) => {
  const { showDevicesGroupCUD, isWex } = useShellRootContext();
  const { devicesState, devicesGroupState } = useStoreState();
  const { allDevicesGroupUuid, ungroupedGroupUuid, mainGroupSelectedId, mainGroupSelectedName } =
    devicesGroupState;
  const { cfgApiRequestOptions, tableData } = devicesState;
  const [groupData, setGroupData] = useState(props.mainGroupData);
  const [showSideBar, setShowSideBar] = useState(false);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const { isMobile, isTablet } = useResponsive();
  const { refreshDevicesTable } = useRefreshHook();

  const nodes = getTreeViewParentNodes(groupData, allDevicesGroupUuid, ungroupedGroupUuid);

  const dispatch = useDispatch();
  const getScrollBarsWidth = (ecpMainContainer) => {
    if (ecpMainContainer && ecpMainContainer.clientHeight < ecpMainContainer.scrollHeight) {
      //scrollbar is visible
      const outsideDivElement = document.createElement('div');
      //add fake outside div to get the scrollbar width
      outsideDivElement.style.visibility = 'hidden';
      outsideDivElement.style.overflow = 'scroll';
      (outsideDivElement.style as any).msOverflowStyle = 'scrollbar';
      document.body.appendChild(outsideDivElement);

      //add fake inside div to get the scrollbar width
      const insideDivElement = document.createElement('div');
      outsideDivElement.appendChild(insideDivElement);

      //calculate scrollbar width
      const scrollbarWidth = outsideDivElement.offsetWidth - insideDivElement.offsetWidth;

      //remove fake divs
      outsideDivElement.parentNode.removeChild(outsideDivElement);
      return scrollbarWidth;
    } else return 0;
  };

  useEffect(() => {
    const ecpMainContainer = document.querySelector('div[id="main-group-tree-wrapper"]');
    if (ecpMainContainer) {
      const newScrollBarWidth = getScrollBarsWidth(ecpMainContainer);
      props.setGroupScrollBarWidth(newScrollBarWidth);
      setScrollBarWidth(newScrollBarWidth);
    }
  });

  useEffect(() => {
    setGroupData(props.mainGroupData);
  }, [props.mainGroupData]);

  const updateSelectedGroupId = (groupId) => {
    dispatch(storeMainGroupSelectedId(groupId));

    const filteredGroup = groupData.filter((group) => {
      return group.id === groupId;
    });
    sessionStorage.setItem('devicesGroupIdForNavigation', groupId);
    dispatch(storeMainGroupSelectedName(filteredGroup[0].name));
    dispatch(storeSelectedItems([]));
    dispatch(storeCurrentPage(1));
    dispatch(storeCfgApiRequestOptions({ ...cfgApiRequestOptions, offset: 0 }));
    dispatch(storeTotalDeviceCountInGroup(filteredGroup[0].devices));
  };

  const handleChangeEvent = (event, id) => {
    updateSelectedGroupId(id);
    event.preventDefault();
  };

  const getHeightState = () => {
    if (props.isFetchingForTable) {
      return 'fetching';
    }
    return tableData.length === 0
      ? 'no-item'
      : Math.min(cfgApiRequestOptions.limit, tableData.length);
  };

  const renderTreeView = (displayedNodes) => {
    return (
      <ThemeProvider density={'high'} shape={'round'}>
        <TreeView
          data-testid={'main-group-tree-view'}
          defaultExpandedNodes={[allDevicesGroupUuid]}
          defaultSelectedNodes={[mainGroupSelectedId]}
          nodes={displayedNodes}
          onChange={handleChangeEvent}
        />
      </ThemeProvider>
    );
  };

  const getResponsiveGroupSelector = () => {
    return (
      <ResponsiveTitle>
        <SelectGroupLabel>
          {`${t('group.selected')} ${t('table.group').toLowerCase()}`}
        </SelectGroupLabel>
        <Button
          data-testid={'main-group-sidebar-button'}
          appearance={'ghost'}
          trailingIcon={<IconChevronRight id={'select-button'} />}
          small
          onClick={() => setShowSideBar(true)}
        >
          {mainGroupSelectedName}
        </Button>
        <SideBar
          data-testid={'main-group-sidebar'}
          content={
            <InfiniteScrollTree nodes={nodes} render={renderTreeView} id={'main-group-tree'} />
          }
          show={showSideBar}
          position="start"
          onClose={() => setShowSideBar(false)}
          onCollapse={() => setShowSideBar(false)}
          onExpand={() => setShowSideBar(true)}
        />
      </ResponsiveTitle>
    );
  };

  return (
    <Wrapper isWex={isWex} scrollBarWidth={scrollBarWidth} data-testid={'main-group-wrapper'}>
      <UpperArea data-testid={'main-group-wrapper-upper-area'}>
        <>
          <Title>{t('group.groups')}</Title>
          {isMobile && getResponsiveGroupSelector()}
        </>

        {showDevicesGroupCUD && (
          <MainGroupButton
            disabled={props.error}
            setShowEditGroupModal={props.setShowEditGroupModal}
          />
        )}
      </UpperArea>

      {props.error ? (
        <GroupErrorMsg />
      ) : (
        <TreeViewWrapper
          data-testid={'main-group-treeview-wrapper'}
          id={'main-group-tree-wrapper'}
          disabled={props.isFetchingForTable}
          responsive={isMobile || isTablet}
          heightState={getHeightState()}
          className={'dui-scrollbar'} //for wx scrollbar
        >
          <InfiniteScrollTree nodes={nodes} render={renderTreeView} id={'main-group-tree'} />
        </TreeViewWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 28px 20px;
  width: ${(props) => {
    //because of box-sizing: border-box in the WX, padding size(40px) has to be added to the width
    return props.isWex ? '292px' : `${252 + props.scrollBarWidth}px`;
  }};
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  padding: 6px 0;
  font-weight: 400;
  flex-basis: 170px;
  flex-shrink: 0;
`;

const ResponsiveTitle = styled.div`
  display: flex;
  align-items: center;
`;

const SelectGroupLabel = styled.span`
  font-weight: bold;
`;

const UpperArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;

  @media (max-width: 576px) {
    ${SelectGroupLabel} {
      display: none;
    }

    button {
      justify-content: left;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    ${Title} {
      display: none;
    }
  }

  @media (min-width: 768px) {
    ${ResponsiveTitle} {
      display: none;
    }
  }
`;
const TreeViewWrapper = styled.div`
  margin-top: 12px;
  overflow: auto;
  flex-grow: 1;

  > ul {
    width: max-content;
  }

  @media (max-width: 768px) {
    display: none;
  }

  ${(props) => {
    if (props.disabled) {
      return css`
        pointer-events: none;
        opacity: 0.5;
      `;
    }
  }}

  height: 100%
`;

export default MainGroup;
