import styled, { keyframes } from 'styled-components';
import React, { useState } from 'react';
import useTableModals from 'src/hooks/useTableModals';
import { t } from 'src/utils/commonMethods';
import { Button, Select, ThemeProvider } from '@veneer/core';
import { TableLeftSelectedItem } from 'src/components/atom';
import tokens from '@veneer/tokens';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TTableContextualFooterProps = {
  selectedItems: any;
  handleFooterCancel: () => void;
  bodyWidth: number;
  entireDataCache: any[];
};

const TableContextualFooter = (props: TTableContextualFooterProps) => {
  const [selectValue, setSelectValue] = useState([]);
  const [isLoadingMfe, setIsLoadingMfe] = useState(false);
  const [showModal, storeShowModal] = useState(false);
  const { getTableModals } = useTableModals(showModal, storeShowModal);
  const { isWex } = useShellRootContext();

  const modalConfigForSelection =
    getTableModals()?.map((options) => ({
      label: options.label,
      value: options.value,
      disabled: options.disabled,
    })) ?? [];

  const modalConfigForShowModal =
    getTableModals()?.map((options) => ({
      component: options.component,
      value: options.value,
    })) ?? [];

  return (
    <>
      <Wrapper isWex={isWex} bodyWidth={props.bodyWidth}>
        <li>
          <TableLeftSelectedItem
            entireDataCache={props.entireDataCache}
            selectedItems={props.selectedItems}
            handleFooterCancel={props.handleFooterCancel}
          />

          <RightActionBox>
            <RightActionFlexBox>
              <ThemeProvider density={'high'} shape={'round'}>
                <SelectWrapper>
                  <Select
                    options={modalConfigForSelection}
                    label={t('table.select_an_action')}
                    data-testid="contextual-footer-select"
                    id="contextual-footer-select"
                    clearIcon={false}
                    value={selectValue}
                    onChange={({ value: v }) => {
                      setSelectValue([v.toString()]);
                    }}
                  />
                </SelectWrapper>
              </ThemeProvider>

              <Button
                id="contextual-footer-continue-button"
                data-testid="contextual-footer-continue-button"
                appearance="primary"
                onClick={() => {
                  setIsLoadingMfe(true);
                  storeShowModal(true);
                }}
                disabled={selectValue.length == 0}
                loading={isLoadingMfe}
              >
                {t('table.continue')}
              </Button>
            </RightActionFlexBox>
          </RightActionBox>
        </li>
      </Wrapper>

      {showModal && (
        <ModalWrapper>
          {modalConfigForShowModal.map((option) => {
            return (
              <>
                {selectValue[0] === option.value &&
                  option.component({ setIsLoadingMfe, showModal: showModal })}
              </>
            );
          })}
        </ModalWrapper>
      )}
    </>
  );
};

const FooterAnimation = keyframes`
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  height:64px;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (min-width: 993px) {    
    width: ${(props) => `${props.bodyWidth}px`}
  }
  display: block;
  margin: 0;
  right: 0;
  z-index: 3;
  animation: 1s ease 0s 1 normal none running ${FooterAnimation};

  li {
    -webkit-box-align: center;
    align-items: center;
    border-color: transparent;
    border-radius: 0;
    border-style: solid;
    border-width: 0 1px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px 24px;
    position: relative;
    height:100%;
    width: 100%;
    background: ${(props) => (props.isWex ? tokens.color.darkNavy1 : '#ebf9ff')};
`;

const RightActionBox = styled.div`
  display: inline-flex;
  flex: 0 0 auto;
  order: 2;
`;

const RightActionFlexBox = styled.div`
  display: flex;

  div {
    padding-right: 10px;

    div > div {
      padding-right: 16px;

      div + input + span {
        padding-right: 0;

        div.vn-select__open-button {
          padding-right: 0;
        }
      }
    }
  }
`;

const SelectWrapper = styled.div`
  min-width: 224px;
  max-width: 320px;
  > div > div {
    background: #ffffff;
  }
`;

const ModalWrapper = styled.div`
  display: none;
`;
export default TableContextualFooter;
